import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import axios from "axios";
import styled from "styled-components";
import PageIntroduction from "../components/PageIntroduction";
import Seo from "../components/seo";

const ContactUs = () => {
  return (
    <Layout>
      <Seo
        title="Contact Us"
        description="Contact the Children's Educational Trust on support@thechildrenseducationaltrust.com"
      />
      <Container>
        <PageIntroduction title="Contact Us" />
        <ContactDetails />
        <ContactForm />
      </Container>
    </Layout>
  );
};

const ContactDetails = () => (
  <Row>
    <Col lg={{ span: "6", offset: "3" }}>
      <ContactStyles>
        <p>
          Phone us on <a href="tel:+44 1625415403">+44 (0) 1625 415403</a>
        </p>
        <p>
          Email us at{" "}
          <a href="mailto:support@thechildrenseducationaltrust.com">
            support@thechildrenseducationaltrust.com
          </a>
        </p>
      </ContactStyles>
      <AlternateDivider>OR</AlternateDivider>
    </Col>
  </Row>
);

const ContactStyles = styled.div`
  background-color: #f7f9fe;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  p {
    font-size: 20px;
  }

  a {
    color: ${({ theme: { colors } }) => colors.green};
  }
`;

const AlternateDivider = styled.h3`
  text-align: center;
  margin: 2rem 0;
  color: ${({ theme: { colors } }) => colors.purpleSecondary};
`;
const ContactForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/cd6f2e8f-eb65-4df0-9ea5-5c3bec5e41e3",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Row>
      <Col lg={{ span: "6", offset: "3" }}>
        <Form>
          <Title>Send us a message</Title>
          <form onSubmit={handleOnSubmit}>
            <Row>
              <Col sm="6" md="6">
                <div className="form-group">
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Name*"
                    required="required"
                  />
                </div>
              </Col>
              <Col sm="6" md="6">
                <div className="form-group">
                  <input
                    name="email"
                    type="text"
                    placeholder="Email Address*"
                    required="required"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col sm="6" md="6">
                <div className="form-group">
                  <input
                    name="phone"
                    type="text"
                    placeholder="Phone Number"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <textarea
                    name="message"
                    rows={5}
                    placeholder="Write a message..."
                    className="form-control"
                    defaultValue={""}
                  />
                </div>
              </Col>
              <Col md="12">
                <button
                  type="submit"
                  id="submit"
                  value
                  disabled={serverState.submitting}
                >
                  Submit
                </button>
                {serverState.status && (
                  <p className={!serverState.status.ok ? "errorMsg" : ""}>
                    {serverState.status.msg}
                  </p>
                )}
              </Col>
            </Row>
          </form>
        </Form>
      </Col>
    </Row>
  );
};

const Form = styled.div`
  background-color: #f7f9fe;
  border-radius: 10px;
  padding: 2rem 2rem 5rem 2rem;
  margin-bottom: 3rem;
  @media (max-width: 1200px) {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .form-control {
    padding: 12px 18px;
    height: auto;
    border-radius: 0;
    border: 1px solid transparent;
    font-size: 14px;
    &:focus {
      background-color: transparent;
      box-shadow: none;
      border-color: ${({ theme: { colors } }) => colors.purpleSecondary};
    }
  }
  button {
    border-radius: 10px;
    padding: 10px 30px;
    background-color: ${({ theme: { colors } }) => colors.purpleSecondary};
    color: #fff;
    display: inline-block;
    line-height: normal;
    border: 1px solid transparent;
    position: relative;
    overflow: hidden;
    z-index: 2;
    vertical-align: middle;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  p {
    text-align: center;
    margin-top: 1rem;
  }
`;

const Title = styled.h3`
  color: ${({ theme: { colors } }) => colors.purpleSecondary};
  margin: 1rem 0;
  text-align: center;
`;
export default ContactUs;
